/** Dependencies */
import React, { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

/** Components */
import Button from 'components/Button/Button';
import CopyToClipboardHTML from 'features/CopyToClipboard/CopyToClipboard';

/** Types */
import { IDesignerUser } from 'components/Card/types';

/** Images */
import FigmaIcon from 'assets/images/svg/figma.svg';
import HeartIcon from 'assets/images/svg/heart-icon.svg';
import RedHeartIcon from 'assets/images/svg/red-heart-icon.svg';
import TrashIcon from 'assets/images/svg/trash-icon.svg';
import InfoIcon from 'assets/images/svg/info-icon.svg';
import EditIcon from 'assets/images/svg/pencil.svg';

/** Styles */
import * as S from './Card.styled';
import { SkeletonCardS } from 'components/SkeletonCard';
import { ROUTE } from 'constants/Routes';
import { useNavigate } from 'react-router-dom';

interface IProps {
  id: number;
  documentId: string;
  image: string;
  title: string;
  message?: string;
  figmaObject: string;
  isAdminCard?: boolean;
  isDesignerCard?: boolean;
  isRemoveButtonsShown?: boolean;
  isEditButtonShown?: boolean;
  designerUser?: IDesignerUser;
  isLiked?: boolean;
  likeCount?: number;
  onCopy?: () => Promise<string | void>;
  onClick?: () => void;
  onApprove?: () => void;
  onSuspend?: () => void;
  onArchive?: () => void;
  onUnArchive?: () => void;
  onDisapprove?: () => void;
  onRemove?: () => void;
  onLike?: (id: number) => void;
}

const Card: FC<IProps> = ({
  id,
  documentId,
  title,
  image,
  message = '',
  figmaObject,
  isAdminCard = false,
  isDesignerCard = false,
  designerUser,
  isRemoveButtonsShown = false,
  isEditButtonShown = false,
  isLiked = false,
  likeCount,
  onCopy = async () => ({}),
  onClick = () => ({}),
  onApprove,
  onSuspend,
  onArchive,
  onUnArchive,
  onDisapprove,
  onRemove = () => ({}),
  onLike = () => ({}),
}) => {
  const navigate = useNavigate();

  const [isCopied, setCopied] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const imageVisibilityStyle = (isDisplayed: boolean): any =>
    isDisplayed ? { display: 'inline-block' } : { display: 'none' };

  const notifyCopy = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const copyResponse = await onCopy?.();

    if (copyResponse !== 'update') {
      setCopied(true);
      toast('Successfully copied to clipboard', {
        duration: 2000,
        position: 'bottom-center',
        style: {
          background: '#2c2c2c',
          color: 'white',
        },
        icon: '👏',
      });
    }
  };

  const handleEditButtonClick = (): void => {
    navigate(ROUTE.EDIT(documentId));
  };

  const handleLikeButtonClick = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    onRemove?.();
    onLike?.(id);
  };

  useEffect(() => {
    if (isCopied) setTimeout(() => setCopied(false), 2000);
  }, [isCopied]);

  return (
    <S.Wrapper onClick={onClick}>
      {(isAdminCard || isDesignerCard) && (
        <S.InfoIcon src={InfoIcon} alt={'info'} />
      )}
      {isEditButtonShown && (
        <S.EditIcon
          onClick={handleEditButtonClick}
          src={EditIcon}
          alt={'edit'}
        />
      )}
      <S.ImageWrapper>
        {!isImageLoaded && <SkeletonCardS.Image />}
        <S.Image
          src={image}
          alt={title}
          style={imageVisibilityStyle(isImageLoaded)}
          onLoad={() => setIsImageLoaded(true)}
        />
      </S.ImageWrapper>
      {(isDesignerCard || isAdminCard) && (
        <S.Message>
          <S.Text>
            {message ? `Admin message: ${message}` : 'No admin message'}
          </S.Text>
          {isAdminCard && (
            <>
              <S.UserName>By: {designerUser?.fullName}</S.UserName>
              {onApprove && (
                <Button onClick={onApprove} key={'approve'} text={'Approve'} />
              )}
              {onArchive && (
                <Button onClick={onArchive} key={'archive'} text={'Archive'} />
              )}
              {onUnArchive && (
                <Button
                  onClick={onUnArchive}
                  key={'unarchive'}
                  text={'Unarchive'}
                />
              )}
              {onSuspend && (
                <Button
                  onClick={onSuspend}
                  key={'suspend'}
                  text={'Suspend'}
                  isOutline={!!onApprove}
                />
              )}
              {onDisapprove && (
                <Button
                  onClick={onDisapprove}
                  key={'disapprove'}
                  text={'Disapprove'}
                  isOutline
                />
              )}
            </>
          )}
        </S.Message>
      )}
      <S.Footer>
        <S.Text>{title}</S.Text>
        <S.Buttons>
          <CopyToClipboardHTML
            onCopy={(e) => notifyCopy(e)}
            textToCopy={figmaObject}
          >
            <Button
              key={'figma'}
              text={isCopied ? 'CMD + V in figma' : 'Copy'}
              icon={FigmaIcon}
            />
          </CopyToClipboardHTML>
          <Button
            key={'like'}
            onClick={handleLikeButtonClick}
            text={likeCount?.toString()}
            icon={
              isRemoveButtonsShown
                ? TrashIcon
                : isLiked || likeCount
                ? RedHeartIcon
                : HeartIcon
            }
          />
        </S.Buttons>
      </S.Footer>
    </S.Wrapper>
  );
};

export default Card;
