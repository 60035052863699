/** Dependencies */
import React, { FC, useEffect } from 'react';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';

/** Features */
import NoContent from 'features/NoContent/NoContent';

/** Hooks */
import { useAppDispatch } from 'hooks/UseAppDispatch';

/** Store */
import { selectColumnCount } from 'store/slices/Pagination.slice';
import { setIsHeaderBottomSideDisabled } from 'store/slices/Header.slice';

/** Styles */
import * as S from 'pages/Error/Error.styled';

const Error: FC = () => {
  const dispatch = useAppDispatch();

  const columnCount = useAppSelector(selectColumnCount);

  const noContentTexts = {
    title: '404',
    description: 'page not found!',
    acknowledgment: '',
  };

  useEffect(() => {
    dispatch(setIsHeaderBottomSideDisabled(false));

    return () => {
      dispatch(setIsHeaderBottomSideDisabled(true));
    };
  }, []);

  return (
    <S.Wrapper columnCount={columnCount}>
      <NoContent isError texts={noContentTexts} />
    </S.Wrapper>
  );
};

export default Error;
