/** Dependencies */
import { useCallback, useEffect, useState } from 'react';

/** Api */
import { getRequest, putRequest } from 'api/apiClient';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Utilities */
import { flattenResponse } from 'utilities/functions';

interface IUseDesignLikesReturn {
  likes?: number[];
  handleLikeClick: (id: number) => Promise<void>;
  getLikes: () => Promise<void>;
  isLikesOnLoad: boolean;
}

export const useDesignLikes = (): IUseDesignLikesReturn => {
  const [likes, setLikes] = useState<number[]>([]);
  const [isLikesOnLoad, setIsLikesOnLoad] = useState<boolean>(true);
  const [likesWrapperObjectId, setLikesWrapperObjectId] = useState<number>(0);
  const { user } = useAuth();

  const getLikes = useCallback(async (): Promise<void> => {
    setIsLikesOnLoad(true);
    const response = await getRequest(
      ENDPOINTS.SYSTEM_USER_WITH_UID(user?.Uid)
    );

    setLikesWrapperObjectId(response.data.data[0]?.documentId);
    setLikes(
      flattenResponse(
        flattenResponse(response?.data?.data)?.[0]?.likes ?? []
      )?.map((like) => like.id) ?? []
    );
    setIsLikesOnLoad(false);
  }, [user?.Uid]);

  const handleLikeClick = useCallback(
    async (id: number): Promise<void> => {
      const newLikes = likes.some((like) => like === id)
        ? likes.filter((like) => like !== id)
        : [...likes, id];

      await putRequest(ENDPOINTS.LIKE_DESIGN(likesWrapperObjectId), {
        likes: newLikes,
        userId: user.Uid,
      });

      await getLikes();
    },
    [getLikes, likes, likesWrapperObjectId, user.Uid]
  );

  useEffect(() => {
    getLikes();
  }, [location.pathname]);

  return {
    ...(likes && likes.length > 0 ? { likes } : {}),
    handleLikeClick,
    getLikes,
    isLikesOnLoad,
  };
};
