/** Dependencies */
import React, { FC } from 'react';

/** Features */
import Page from 'features/Page/Page';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Components */
import Card from 'components/Card/Card';

/** Hooks */
import { useDesigns } from 'hooks/UseDesigns';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

const MyPending: FC = () => {
  const { user } = useAuth();

  const noContentTexts = {
    title: 'Pending',
    description:
      'You currently have no pending designs in your queue. Keep an eye out for future opportunities or upload new designs for review.',
    acknowledgment:
      'Thank you for your continued creativity and contributions!',
  };

  const { designs, isPageLoading } = useDesigns({
    endpoint: `${ENDPOINTS.PENDING_DESIGNS}${ENDPOINTS.MY_DESIGNS(user?.Uid)}`,
  });

  return (
    <Page
      isContentShown={!!designs?.length && designs?.length > 0}
      noContentTexts={noContentTexts}
      isLoading={isPageLoading}
    >
      {designs?.map((card, i) => (
        <Card
          key={i}
          id={card.id}
          documentId={card.documentId}
          title={card.subjectLine}
          image={card.image.url}
          message={card.message}
          figmaObject={card.figmaObject}
          designerUser={card.designerUser}
          likeCount={card.likedUsers.length}
          isDesignerCard
          isEditButtonShown
        />
      ))}
    </Page>
  );
};

export default MyPending;
