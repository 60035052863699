/** Dependencies */
import React, { FC, useEffect, useState, WheelEvent } from 'react';
import Slider from 'react-slick';

/** Components */
import Tag from 'components/Tag/Tag';

/** Images */
import MinimizeIcon from 'assets/images/svg/minimize.svg';
import MaximizeIcon from 'assets/images/svg/maximize.svg';

/** Types */
import { ITag } from 'features/Header/types';

/** Styles */
import * as S from 'features/Tags/Tags.styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface IProps {
  title: string;
  tags: ITag[];
  onSelect?: (selectedTags: ITag[]) => void;
}

const Tags: FC<IProps> = ({ title, tags, onSelect }) => {
  let sliderRef: Slider | null = null;
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
  const [isMinimized, setIsMinimized] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);

  const handleTitleClick = (): void => {
    setIsMinimized(!isMinimized);
  };

  const handleTagClick = (tag: ITag): void => {
    const isTagSelectedBefore = selectedTags.some((t) => t.id === tag.id);

    isTagSelectedBefore
      ? setSelectedTags((list) =>
          list.filter((t) => t.id !== tag.id && t.text.toLowerCase() !== 'all')
        )
      : setSelectedTags([...selectedTags, tag]);
  };

  const handleWheel = (e: WheelEvent<HTMLDivElement>): void => {
    if (e.deltaY > 0) {
      sliderRef?.slickGoTo(slideIndex - 1);
    } else if (e.deltaY < 0) {
      sliderRef?.slickGoTo(slideIndex + 1);
    }
  };

  useEffect(() => {
    onSelect?.(selectedTags);
  }, [selectedTags]);

  return (
    <S.Wrapper>
      <S.Title onClick={handleTitleClick}>
        <S.TitleIcon src={isMinimized ? MaximizeIcon : MinimizeIcon} />
        <S.TitleText>{title}</S.TitleText>
      </S.Title>
      <S.ContentContainer isMinimized={isMinimized}>
        <S.PrevArrow onClick={() => sliderRef?.slickGoTo(slideIndex + 1)} />
        <S.Content onWheel={handleWheel}>
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            infinite
            variableWidth
            speed={500}
            slidesToShow={20}
            slidesToScroll={1}
            afterChange={() => setUpdateCount(updateCount + 1)}
            beforeChange={(current, next) => setSlideIndex(next)}
          >
            <Tag
              isActive={selectedTags.length <= 0}
              onClick={() => setSelectedTags([])}
            >
              All
            </Tag>
            {tags.map((tag, i) => (
              <Tag
                key={i}
                isActive={selectedTags.some((t) => t.id === tag.id)}
                onClick={() => handleTagClick(tag)}
              >
                {tag.text}
              </Tag>
            ))}
          </Slider>
        </S.Content>
        <S.NextArrow onClick={() => sliderRef?.slickGoTo(slideIndex - 1)} />
      </S.ContentContainer>
    </S.Wrapper>
  );
};

export default Tags;
