import styled, { css } from 'styled-components';

const TagsWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: ${convertVW(12)};
  `}
`;

const TagsTitle = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(12)};
    cursor: pointer;
  `}
`;

const TagsTitleIcon = styled.img`
  ${({ theme: { convertVW } }) => css`
    max-width: ${convertVW(14)};
    max-height: ${convertVW(14)};
  `}
`;

const TagsTitleText = styled.div`
  ${({ theme: { convertVW } }) => css`
    user-select: none;
    font-family: 'DM Sans', sans-serif;
    font-size: ${convertVW(14)};
    line-height: ${convertVW(20)};
    color: #6b7280;
    text-transform: uppercase;
  `}
`;

const TagsContentContainer = styled.div<{ isMinimized: boolean }>`
  ${({ theme: { convertVW }, isMinimized }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(24)};
    height: ${isMinimized ? 0 : convertVW(40)};
    transition: height 1s ease;
    width: 100%;
    overflow: hidden;
  `}
`;

const TagsContent = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    height: ${convertVW(40)};
    align-items: center;
    width: 93%;
    overflow: hidden;
    border-radius: ${convertVW(24)};

    &::-webkit-scrollbar {
      display: none;
    }

    .slick-slide {
      margin-right: ${convertVW(8)};
    }
  `}
`;

const Arrow = css`
  ${({ theme: { convertVW } }) => css`
    position: relative;
    width: ${convertVW(20)};
    height: ${convertVW(20)};
    border: #6b7280 ${convertVW(1)} solid;
    color: #6b7280;
    border-radius: 50%;
    cursor: pointer;

    &:before {
      content: '←';
      display: block;
      width: 100%;
      height: 100%;

      font-size: ${convertVW(20)};
      line-height: ${convertVW(17)};
    }

    &:active {
      border-color: #000;
      color: #000;
    }
  `}
`;

const TagsPrevArrow = styled.div`
  ${() => css`
    ${Arrow};

    &:before {
      content: '←';
    }
  `}
`;

const TagsNextArrow = styled.div`
  ${() => css`
    ${Arrow};

    &:before {
      content: '→';
    }
  `}
`;

export {
  TagsWrapper as Wrapper,
  TagsTitle as Title,
  TagsTitleIcon as TitleIcon,
  TagsTitleText as TitleText,
  TagsContentContainer as ContentContainer,
  TagsContent as Content,
  TagsPrevArrow as PrevArrow,
  TagsNextArrow as NextArrow,
};
