/** Dependencies */
import React, { FC } from 'react';

/** Features */
import Page from 'features/Page/Page';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Components */
import Card from 'components/Card/Card';

/** Hooks */
import { useDesigns } from 'hooks/UseDesigns';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

const MyDisapproved: FC = () => {
  const { user } = useAuth();

  const noContentTexts = {
    title: 'Disapproved',
    description:
      'You currently have no disapproved designs. Keep up the great work, and continue submitting your creative ideas!',
    acknowledgment: '',
  };

  const { designs, isPageLoading } = useDesigns({
    endpoint: `${ENDPOINTS.DISAPPROVED_DESIGNS}${ENDPOINTS.MY_DESIGNS(
      user?.Uid
    )}`,
  });

  return (
    <Page
      isContentShown={!!designs?.length && designs?.length > 0}
      noContentTexts={noContentTexts}
      isLoading={isPageLoading}
    >
      {designs?.map((card, i) => (
        <Card
          key={i}
          id={card.id}
          documentId={card.documentId}
          title={card.subjectLine}
          image={card.image.url}
          message={card.message}
          figmaObject={card.figmaObject}
          designerUser={card.designerUser}
          likeCount={card.likedUsers.length}
          isDesignerCard
          isEditButtonShown
        />
      ))}
    </Page>
  );
};

export default MyDisapproved;
