/** Dependencies */
import React, { FC } from 'react';

/** Styles */
import * as S from './Tag.styled';

interface IProps {
  children: string;
  isActive: boolean;
  onClick: () => void;
}

const Tag: FC<IProps> = ({ children, isActive, onClick }) => {
  return (
    <S.Wrapper onClick={onClick} isActive={isActive}>
      {children}
    </S.Wrapper>
  );
};

export default Tag;
