/** Dependencies */
import { useCallback } from 'react';

/** Store */
import { selectSortItem } from 'store/slices/Sort.slice';
import {
  selectFilterIndustries,
  selectFilterSubjects,
} from 'store/slices/Header.slice'; /** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';

interface IUseDesignFiltersReturn {
  returnFilters: () => string;
}

interface IUseDesignFiltersProps {
  likes?: number[];
  isSavedPage: boolean;
}

export const useDesignFilters = ({
  likes,
  isSavedPage,
}: IUseDesignFiltersProps): IUseDesignFiltersReturn => {
  const sortOption = useAppSelector(selectSortItem);
  const filterIndustries = useAppSelector(selectFilterIndustries);
  const filterSubjects = useAppSelector(selectFilterSubjects);

  const returnFilters = useCallback((): string => {
    let likedIdsFilter = isSavedPage ? '&filters[id][$in]=0' : '';
    if (likes && likes?.length && likes?.length > 0 && isSavedPage) {
      likes.map((like) => {
        likedIdsFilter += `&filters[id][$in]=${like}`;
      });
    }

    let subCategoryFilter = '';
    filterSubjects.map((subject) => {
      subCategoryFilter += `&filters[subCategories][id][$in]=${subject.id}`;
    });

    let industryFilter = '';
    filterIndustries.map((industry) => {
      industryFilter += `&filters[industries][id][$in]=${industry.id}`;
    });

    const sortFilter =
      sortOption.key === 'random'
        ? `&randomSort=true`
        : sortOption?.key && sortOption?.order
        ? `&sort[0]=${sortOption.key}:${sortOption.order}`
        : '';

    return `${likedIdsFilter}${subCategoryFilter}${industryFilter}${sortFilter}`;
  }, [
    likes,
    isSavedPage,
    filterSubjects,
    filterIndustries,
    sortOption.key,
    sortOption.order,
  ]);

  return { returnFilters };
};
