/** Dependencies */
import React from 'react';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';
import { useAppDispatch } from 'hooks/UseAppDispatch';

/** Api */
import { getRequest, putRequest } from 'api/apiClient';

/** Features */
import CopyLimitModal from 'features/CopyLimitModal/CopyLimitModal';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Store */
import {
  selectCopyCount,
  setIsCopyCountRequestTriggered,
} from 'store/slices/Header.slice';
import { setModal } from 'store/slices/Modal.slice';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

export const useDesignCopy = (): (() => Promise<string | void>) => {
  const dispatch = useAppDispatch();
  const { user, plans } = useAuth();

  const copyCount = useAppSelector(selectCopyCount);

  const selectedPlan = plans.find(
    (plan) => plan.Uid === user?.Account?.CurrentSubscription?.Plan?.Uid
  );

  const isPro = selectedPlan?.Name === 'Pro';

  return async (): Promise<string | void> => {
    if (!isPro) {
      if (copyCount > 0) {
        const userResponse = await getRequest(
          ENDPOINTS.SYSTEM_USER_WITH_UID(user.Uid)
        );

        await putRequest(
          ENDPOINTS.SYSTEM_USER_WITH_ID(userResponse.data.data[0].documentId),
          {
            copyCount: copyCount - 1,
          }
        );

        dispatch(setIsCopyCountRequestTriggered(true));
      } else {
        dispatch(
          setModal({
            isVisible: true,
            title: `Copy Limit Reached`,
            content: <CopyLimitModal />,
          })
        );
        return 'update';
      }
    }
  };
};
