/** Dependencies */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

/** Api */
import { getRequest, postRequest, putRequest } from 'api/apiClient';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Utilities */
import {
  capitalizeFirstLetters,
  flattenResponse,
  toastOptions,
} from 'utilities/functions';

/** Store */
import { setIsCountsRequestTriggered } from 'store/slices/Sidebar.slice';
import { setIsLoadingModal, setModal } from 'store/slices/Modal.slice';

/** Hooks */
import { useAppDispatch } from 'hooks/UseAppDispatch';

/** Features */
import ApproveModal from 'features/ApproveModal/ApproveModal';

interface IUseDesignApproveReturn {
  handleDesignApprove: () => Promise<void>;
}

interface IUseDesignApproveProps {
  id: string;
  type: string;
  getDesigns: () => Promise<void>;
  getLikes: () => Promise<void>;
}

export const useDesignApprove = ({
  id,
  type,
  getDesigns,
  getLikes,
}: IUseDesignApproveProps): IUseDesignApproveReturn => {
  const dispatch = useAppDispatch();
  const [design, setDesign] = useState<any>();

  const isProcessArchiving = type === 'archive' || 'unarchive';
  const approveModalParam = type === 'suspend' ? null : type === 'approve';
  const endpoints = {
    getDesign:
      type === 'unarchive'
        ? ENDPOINTS.ARCHIVED_DESIGN(id)
        : ENDPOINTS.DESIGN(id),
    updateDesign:
      type === 'unarchive'
        ? ENDPOINTS.UNARCHIVE_DESIGN
        : ENDPOINTS.ARCHIVE_DESIGN,
  };

  const alterDesignerTotalBalance = async (): Promise<void> => {
    await putRequest(
      ENDPOINTS.DESIGNER_USER_WITH_ID(design.designerUser.documentId),
      {
        totalBalance:
          design.designerUser.totalBalance + design.designerUser.fixedFee,
      }
    );
  };

  const createTransaction = async (): Promise<void> => {
    await postRequest(ENDPOINTS.TRANSACTIONS, {
      designerUser: design?.designerUser.documentId,
      balanceDelta: design?.designerUser.fixedFee,
      totalBalance:
        design?.designerUser.totalBalance + design?.designerUser.fixedFee,
    });
  };

  const postConfirmingModal = async (): Promise<void> => {
    approveModalParam && (await alterDesignerTotalBalance());
    approveModalParam && (await createTransaction());

    await getDesigns();
    await getLikes();

    dispatch(setIsLoadingModal(false));
    dispatch(setIsCountsRequestTriggered(true));
    dispatch(setModal({ isVisible: false }));

    toast(
      `${capitalizeFirstLetters(type)} process successful`,
      toastOptions('👏')
    );
  };

  const handleApproveModal = async (message: string): Promise<void> => {
    dispatch(setIsLoadingModal(true));

    await putRequest(ENDPOINTS.DESIGN(id), {
      isApproved: approveModalParam,
      message,
    }).then(async () => {
      await postConfirmingModal();
    });
  };

  const handleArchiveModal = async (): Promise<void> => {
    dispatch(setIsLoadingModal(true));

    await postRequest(
      endpoints.updateDesign,
      {
        documentId: id,
      },
      undefined,
      true
    ).then(async () => {
      await postConfirmingModal();
    });
  };

  useEffect(() => {
    void getRequest(endpoints.getDesign).then((response) => {
      setDesign(flattenResponse([response.data.data])[0]);
    });
  }, [id, type]);

  const handleDesignApprove = async (): Promise<void> => {
    dispatch(
      setModal({
        isVisible: true,
        title: `${capitalizeFirstLetters(type)} Design`,
        content: (
          <ApproveModal
            type={type}
            isApproved={design.isApproved}
            designerName={design?.designerUser?.fullName}
            designName={design.subjectLine}
            onCancel={() => dispatch(setModal({ isVisible: false }))}
            onConfirm={
              isProcessArchiving ? handleArchiveModal : handleApproveModal
            }
          />
        ),
      })
    );
  };

  return { handleDesignApprove };
};
