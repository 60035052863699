/** Dependencies */
import React, { FC } from 'react';

/** Features */
import Page from 'features/Page/Page';

/** Hooks */
import { useDesignCopy } from 'hooks/UseDesignCopy';
import { useDesigns } from 'hooks/UseDesigns';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Components */
import Card from 'components/Card/Card';

const Saved: FC = () => {
  const noContentTexts = {
    title: 'Favorites',
    description:
      'You haven`t added any designs to your favorites yet. Browse our collection and save your favorite designs for easy access later!',
    acknowledgment: '',
  };

  const handleDesignCopy = useDesignCopy();

  const { designs, isPageLoading, likes, handleLikeClick } = useDesigns({
    endpoint: ENDPOINTS.APPROVED_DESIGNS,
    isSavedPage: true,
  });

  return (
    <Page
      isContentShown={!!designs?.length && designs?.length > 0}
      noContentTexts={noContentTexts}
      isLoading={isPageLoading}
    >
      {designs?.map((card, i) => (
        <Card
          key={i}
          id={card.id}
          documentId={card.documentId}
          title={card.subjectLine}
          image={card.image.url}
          message={card.message}
          figmaObject={card.figmaObject}
          designerUser={card.designerUser}
          isLiked={likes?.some((like) => like === card.id)}
          onLike={handleLikeClick}
          onCopy={handleDesignCopy}
        />
      ))}
    </Page>
  );
};

export default Saved;
