/** Dependencies */
import React, { FC, ReactElement, useEffect } from 'react';

/** Features */
import SkeletonList from 'features/SkeletonList/SkeletonList';
import NoContent from 'features/NoContent/NoContent';

/** Store */
import { selectColumnCount } from 'store/slices/Pagination.slice';
import { setIsHeaderBottomSideDisabled } from 'store/slices/Header.slice';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';
import { useAppDispatch } from 'hooks/UseAppDispatch';

/** Styles */
import * as S from './Page.styled';

interface IPageProps {
  children?: ReactElement | ReactElement[];
  isLoading: boolean;
  isContentShown: boolean;
  noContentTexts?: {
    title: string;
    description: string;
    acknowledgment: string;
  };
}

const Page: FC<IPageProps> = ({
  children,
  isLoading,
  noContentTexts,
  isContentShown,
}) => {
  const dispatch = useAppDispatch();
  const columnCount = useAppSelector(selectColumnCount);

  useEffect(() => {
    dispatch(setIsHeaderBottomSideDisabled(false));

    return () => {
      dispatch(setIsHeaderBottomSideDisabled(true));
    };
  }, []);

  return (
    <S.Wrapper columnCount={columnCount}>
      {isLoading ? (
        <SkeletonList />
      ) : isContentShown ? (
        <>{children}</>
      ) : (
        <NoContent texts={noContentTexts} />
      )}
    </S.Wrapper>
  );
};

export default Page;
