/** Dependencies */
import { useEffect, useState } from 'react';

/** Api */
import { getRequest } from 'api/apiClient';

/** Components */
import { ICard } from 'components/Card/types';

/** Hooks */
import { useDesignFilters } from 'hooks/UseDesignFilters';
import { useDesignLikes } from 'hooks/UseDesignLikes';

/** Utilities */
import { flattenResponse } from 'utilities/functions';

interface IUseDesignsReturn {
  designs: ICard[];
  isPageLoading: boolean;
  getDesigns: () => Promise<void>;
  getLikes: () => Promise<void>;
  likes?: number[];
  handleLikeClick: (id: number) => Promise<void>;
}

interface IUseDesignsProps {
  endpoint: string;
  isSavedPage?: boolean;
  isArchivedPage?: boolean;
}

export const useDesigns = ({
  endpoint,
  isSavedPage = false,
  isArchivedPage = false,
}: IUseDesignsProps): IUseDesignsReturn => {
  const [designs, setDesigns] = useState<ICard[]>([]);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const { likes, getLikes, handleLikeClick, isLikesOnLoad } = useDesignLikes();

  const { returnFilters } = useDesignFilters({ likes, isSavedPage });

  const getDesigns = async (): Promise<void> => {
    setIsPageLoading(true);

    try {
      const response = await getRequest(
        `${endpoint}${isArchivedPage ? '' : returnFilters()}`
      );
      setDesigns(flattenResponse(response.data.data));
    } catch (error) {
      console.error('Error fetching designs:', error);
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    !isLikesOnLoad && void getDesigns();
  }, [location.pathname, isLikesOnLoad, returnFilters]);

  return {
    designs,
    isPageLoading,
    getDesigns,
    getLikes,
    ...(likes && likes.length > 0 ? { likes } : {}),
    handleLikeClick,
  };
};
