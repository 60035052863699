/** Dependencies */
import React, { FC, useEffect, useState } from 'react';

/** Features */
import Page from 'features/Page/Page';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Components */
import Card from 'components/Card/Card';

/** Hooks */
import { useDesigns } from 'hooks/UseDesigns';
import { useDesignApprove } from 'hooks/UseDesignApprove';

const Approved: FC = () => {
  const [action, setAction] = useState<{ id: string; type: string }>();

  const noContentTexts = {
    title: 'Approved',
    description: 'There is currently no approved designs.',
    acknowledgment: '',
  };

  const { designs, isPageLoading, likes, getLikes, getDesigns } = useDesigns({
    endpoint: ENDPOINTS.APPROVED_DESIGNS,
  });

  const { handleDesignApprove } = useDesignApprove({
    id: action?.id ?? '',
    type: action?.type ?? '',
    getLikes,
    getDesigns,
  });

  useEffect(() => {
    if (action?.id) void handleDesignApprove();
  }, [action?.id]);

  return (
    <Page
      isContentShown={!!designs?.length && designs?.length > 0}
      noContentTexts={noContentTexts}
      isLoading={isPageLoading}
    >
      {designs?.map((card, i) => (
        <Card
          key={i}
          id={card.id}
          documentId={card.documentId}
          title={card.subjectLine}
          image={card.image.url}
          message={card.message}
          figmaObject={card.figmaObject}
          designerUser={card.designerUser}
          likeCount={card.likedUsers.length}
          isLiked={likes?.some((like) => like === card.id)}
          isAdminCard
          onArchive={() => setAction({ id: card.documentId, type: 'archive' })}
        />
      ))}
    </Page>
  );
};

export default Approved;
